import React from "react"

import { Modal, Button } from "react-bootstrap"
/* 
function MyVerticallyCenteredModal({ props }) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

function App({ children, childLink }) {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
      <div onClick={() => setModalShow(true)}>{childLink}</div>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        {children}
      </MyVerticallyCenteredModal>
    </>
  )
} */

/* render(<App />); */

const MyModal = ({ children, childLink, modalTitle, className }) => {
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function App() {
    const [modalShow, setModalShow] = React.useState(false)

    return (
      <>
        <div
          style={{
            cursor: `pointer`,
          }}
          role = "button"
          tabIndex={0}
          onClick={() => setModalShow(true)}
          onKeyDown={() => setModalShow(true)}
          className={`${className}`}
        >
        
          {childLink}
        </div>
        {/*       <Button variant="primary" onClick={() => setModalShow(true)}>
              Launch vertically centered modal
            </Button> */}

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    )
  }

  return (
    <>
      <App />
    </>
  )
}

export default MyModal
