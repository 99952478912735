import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

const ConvertLink = ({ path, children, index }) => {
  if (path.includes("http")) {
    return (
      <a key={index} href={path}>
        {children}
      </a>
    )
  } else {
    return (
      <Link key={index} to={`/${path.replace("/","")}`}>
        {children}
      </Link>
    )
  }
}

ConvertLink.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  index: PropTypes.number,
}

ConvertLink.defaultProps = {
  path: "",
}

export default ConvertLink
